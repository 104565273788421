<template>
    <footer class="footer has-cards">
        <div class="container">
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-md-6">
                    <div class="copyright">
                        &copy; {{year}}
                        <b-link :to="{name:'public-landing'}">hustleforge.com</b-link>
                    </div>
                </div>
                <div class="col-md-6">
                    <ul class="nav nav-footer justify-content-end">
                      <b-link
                      class="pr-5 PL-5"
                      href="https://www.linkedin.com/company/hustleforge/"
                      target="_blank"
                    >
                    <feather-icon
                        icon="LinkedinIcon"
                        class="ml-60"
                      />
                    </b-link>

                      
                      <b-link class="pr-1" :to="{name:'privacy-policy'}">Privacy Policy</b-link> | 
                      <b-link class="pr-1 pl-1" :to="{name:'public-terms'}">Terms & Conditions</b-link> |
                      <b-link class="pl-1" :to="{name:'press-kit'}">Press kit</b-link>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import {BLink} from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  name: 'app-footer-landing',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>
<style>
</style>
