<template>
    <!-- NAVBAR -->
    <b-navbar
      toggleable="lg"
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed navbar-dark"
    >
        <div class="container">
            <b-navbar-brand :to="{name:'public-landing'}" >
                <b-img :src="require('@/assets/images/logo/hflogo.png')" class="ml-2" style="height:30px;" alt="hustleforge" />
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse" class="mr-2" variant="primary"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto dark">
                  <b-button :to="{name:'public-startup'}" variant="primary">List&nbsp;Idea</b-button>
                  <b-button :to="{name:'public-experts'}" variant="primary ml-1">Get&nbsp;Paid</b-button>
                  <!-- <b-button :to="{name:'public-premium'}" variant="primary" class="ml-1">Premium</b-button> -->
                  <b-nav-item :to="{name:'auth-register'}" class="nav-link pl-1">Signup</b-nav-item>
                  <b-nav-item :to="{name:'auth-login'}" class="nav-link pl-1">Login</b-nav-item>
                  <b-nav-item :to="{name:'public-pricing'}" class="nav-link pl-1">Pricing</b-nav-item>
                  <b-nav-item :to="{name:'public-about'}" class="nav-link pl-1">About</b-nav-item>
               </b-navbar-nav>
            </b-collapse>
        </div>
    </b-navbar>

</template>
<script>
import {
    BImg,
    BNavbar,
    BNavbarBrand,
    BNavItem,
    BNavText,
    BCollapse,
    BNavbarToggle,
    BNavbarNav,
    BNavForm,
    BNavItemDropdown,
    BDropdownItem,
    BButton,
    BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
      BImg,
      BNavbar,
      BNavbarBrand,
      BNavItem,
      BNavText,
      BCollapse,
      BNavbarToggle,
      BNavbarNav,
      BNavForm,
      BNavItemDropdown,
      BDropdownItem,
      BButton,
      BFormInput,
  },
};
</script>
<style>
</style>
