<template>
    <div>
        <app-header />
        <main>
            <section class="section section-lg">
                <div class="container">
                    <div class="row row-grid justify-content-center">
                        <div class="col-lg-9 text-center">
                            <h3 class="display-3"><img src="hflogocom.png" class="img-center img-fluid w-50">
                                <span class="text-primary">About</span>
                            </h3>
                        </div>
                        <div>
                            <p class="lead">The first rule of any startup? Talk to people. The hardest part of any startup? Finding the right people to talk to. </p>
                            <p class="lead">Hustleforge was started by Robert Larmore and Ken Munsterman: two entrepreneurs who struggled with finding the right feedback from the right people at the right time. We think finding great mentorship, expertise and industry knowledge should be as easy as scheduling lunch with a friend.</p>
                            <p class="lead">We know that great mentorship and insider knowledge is the best first step on a startup journey, saving entrepreneurs time, effort and heartbreak. We’ve made it our mission to make connecting easy, simple, quick and affordable. </p>
                            <p class="lead">Join us today, either by offering your expertise as an expert (and making money in the process) or by seeking expertise for your benefit!</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <app-footer />
    </div>
</template>

<script>
import { getFirestore, collection, addDoc } from "firebase/firestore";
const db = getFirestore()

import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea, 
  BCard,
  BCardText,
  BCardActions,
  BAvatar,
  BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppFooter from './AppFooter.vue'
import AppHeader from './AppHeader.vue'

export default {
    components: {
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BFormTextarea, 
        BCard,
        BCardText,
        BCardActions,
        BAvatar,
        ValidationProvider,
        ValidationObserver,
        AppFooter,
        AppHeader,
        BImg,
    },
    name: "home",
    title: 'Hustleforge',
    data: function(){
        return {
        }
    },
};
</script>
